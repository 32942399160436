function transformProductSize({
  quantity,
  productSize,
  packagingType = '',
}: {
  quantity: number;
  productSize: string;
  packagingType?: string | null;
}): string {
  const type = packagingType ? ` ${packagingType}` : '';

  return `(${quantity}x ${productSize}${type})`;
}

export default transformProductSize;
