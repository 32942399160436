import { useCallback, useContext, useEffect, useMemo } from 'react';
import { currenciesIcons } from 'context/CartContextProvider/currenciesIcons';
import { CartContext } from 'context/CartContextProvider/CartContextProvider';
import { mergeProductsData } from 'utils/shopifyUtils/mergeProductsData';
import { TUseShopifyArgs, TUseShopifyRes } from './model';

const useShopifyHelper = ({
  isShopify,
  lang,
  cartProductsData,
}: TUseShopifyArgs): TUseShopifyRes => {
  const { purchaseSettings, allShopProducts, createClient, cartItems, setIsModalOpen } =
    useContext(CartContext);

  const {
    productPricePlaceholderSingle = '',
    productPricePlaceholder = '',
    bundlePricePlaceholder = '',
  } = purchaseSettings;

  useEffect(() => {
    if (isShopify) {
      createClient(isShopify, lang);
    }
  }, [isShopify, lang]);

  const getProductById = useCallback(
    (productId, varId) => {
      if (!productId || !varId) return null;
      const neededProduct = allShopProducts[varId];

      if (!neededProduct) return null;

      return {
        ...neededProduct,
        currencieLabel: currenciesIcons[neededProduct?.priceV2?.currencyCode],
      };
    },
    [JSON.stringify(allShopProducts)]
  );

  const mergeCmsAndCartProductsData = useCallback(
    (newCartItems) => mergeProductsData(newCartItems, cartProductsData),
    [JSON.stringify(cartItems), JSON.stringify(cartProductsData)]
  );

  const mergeCmsShopifyProducts = useCallback(
    (cmsProducts) => {
      if (!allShopProducts) {
        return cmsProducts?.nodes;
      }

      return cmsProducts?.nodes.map((product) => {
        const neededShopProduct = getProductById(product.shopifyId, product.skuId);

        const { productVariantsShopifyData, productType } = product;

        const productVariantData = productVariantsShopifyData
          ?.map((variant) => getProductById(variant.shopifyId, variant.skuId))
          ?.reduce(
            (acc, variant) =>
              variant?.price
                ? [...acc, { price: variant.price, currencieLabel: variant.currencieLabel }]
                : acc,
            []
          )
          .sort((a, b) => a.price - b.price);
        const currencieLabel =
          currenciesIcons[neededShopProduct?.priceV2.currencyCode] ||
          productVariantData?.[0]?.currencieLabel ||
          null;

        const getProductPrices = () => {
          if (!productVariantData?.length) return null;

          return productVariantData.length > 1
            ? productPricePlaceholder
                ?.replace('%currency%', currencieLabel)
                .replace('%price%', productVariantData[0].price)
            : productPricePlaceholderSingle
                ?.replace('%currency%', currencieLabel)
                .replace('%price%', productVariantData[0].price);
        };

        const productVariantPrices = getProductPrices();

        const { price: newPrice, compareAtPrice: priceToCompare } = neededShopProduct || {};

        const productPrice = newPrice
          ? bundlePricePlaceholder
              ?.replace('%currency%', currencieLabel)
              .replace('%price%', newPrice)
          : null;

        const resPrice = productType === 'product' ? productVariantPrices : productPrice;

        const variantsQuantity = Array.isArray(productVariantData)
          ? productVariantData.length
          : null;

        const oldPrice = priceToCompare ? `${currencieLabel}${priceToCompare}` : null;

        const productDiscount =
          newPrice && priceToCompare
            ? (((+priceToCompare - +newPrice) / +priceToCompare) * 100).toFixed()
            : 0;

        return {
          ...product,
          variantsQuantity,
          discount: productDiscount,
          price: resPrice,
          oldPrice,
          availableQuantity: neededShopProduct?.quantityAvailable,
        };
      });
    },
    [JSON.stringify(allShopProducts)]
  );

  const boughtProducts = useMemo(
    () => mergeCmsAndCartProductsData(cartItems),
    [JSON.stringify(cartItems)]
  );

  return {
    boughtProducts,
    mergeCmsShopifyProducts,
    onClose: () => setIsModalOpen(false),
  };
};

export default useShopifyHelper;
