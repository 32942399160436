import React, { FC, useContext, useState } from 'react';

import Button from 'common/Button';

import useShopify from 'hooks/useShopify';
import { CartContext } from 'context/CartContextProvider/CartContextProvider';

import { AddToCartButtonAndModalProps } from './models';

const AddToCartButton: FC<AddToCartButtonAndModalProps> = ({
  productskuId,
  productShopifyId,
  buttonVariant,
  isShopify,
  lang,
  cartProductsData,
  productTitle,
}) => {
  const { checkoutAndAddProduct, productInfo, addButtonText, btnLabel } = useShopify({
    isShopify,
    lang,
    skuId: productskuId,
    shopifyId: productShopifyId,
    cartProductsData,
    productTitle,
  });

  const { isLoading } = useContext(CartContext);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const addProductToCheckout = () => {
    setIsButtonLoading(true);

    checkoutAndAddProduct().then(() => setIsButtonLoading(false));
  };

  return productInfo?.price ? (
    <Button
      isLoading={isButtonLoading}
      disabled={productInfo?.quantityAvailable === 0 || isLoading}
      variant={buttonVariant}
      clickHandler={addProductToCheckout}
      classes="add-to-cart__button"
      iconPrefix="cart"
      ariaLabel={btnLabel}
    >
      {addButtonText}
    </Button>
  ) : null;
};
export default AddToCartButton;
