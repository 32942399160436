import React, { FC } from 'react';

import { IProductCardTitle } from '@shared/types';
import { DangerouslySetInnerHtml } from 'layout';

const ProductCardTitle: FC<IProductCardTitle> = ({ titleToShow }) => (
  <DangerouslySetInnerHtml element="h3" className="product-card__title" html={titleToShow} />
);

export default ProductCardTitle;
