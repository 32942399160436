import { UmbracoTags } from '@shared/types/umbraco/tags';
import { parseBoolean } from './parseBoolean';

const getFamily = (tags: UmbracoTags.IBaseTag[]): UmbracoTags.IBaseTag | null => {
  if (!tags?.length) return null;
  const familyTag = tags.filter((tag) => (parseBoolean(tag.isFamily) ? tag : null));

  return familyTag?.length ? familyTag[0] : null;
};

export default getFamily;
